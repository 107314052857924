.modal{
    width:90%;
    margin-left:5%;
    text-align: center;
    font-family: 'Ubuntu';
    font-weight:700;
    .error{
        display:block;
        color: white;
        font-size:1em;
        margin-bottom:20px;
    }
    .info{
        display:block;
        color: white;
        font-size:1.5em;
        text-transform: uppercase;
        height:300px;
    }
    .hidden{
        display:none;
    }
}